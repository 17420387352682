
function getMatchingElement(elements: Element[], target: Element | null): Element | null {
	if (target == null) {
		return null;
	}

	if (elements.some(x => x === target)) {
		return target;
	}

	return getMatchingElement(elements, target.parentElement);
}

export function listenOn(root: Document | Element, type: string, selector: string, callback: (event: Event, target: Element) => void) {
	if (typeof root.addEventListener === "undefined") {
		return;
	}

	root.addEventListener(type, event => {
		const target = getMatchingElement(Array.from(document.querySelectorAll(selector)), event.target as Element);
		if (target != null) {
			callback(event, target);
		}
	});
}

export function getAttribute(element: Element, name: string): string | null {
	const attr = element.attributes.getNamedItem(name);

	return attr != null ? attr.value : null;
}
