﻿import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { getAttribute, listenOn } from "Helpers/DomHelpers";

interface IConfirmPlayerModalState {
	show: boolean;
	target: string;
}

function ConfirmPlayerModal() {
	const [state, setState] = useState<IConfirmPlayerModalState>({ show: false, target: "" });

	function show(target: string) {
		setState({ show: true, target });
		document.body.classList.add("modal-open");
	}

	function hide() {
		setState({ show: false, target: "" });
		document.body.classList.remove("modal-open");
	}

	function cancel() {
		hide();
	}

	function install() {
		location.href = "/download/";
	}

	function confirm() {
		localStorage.setItem("screamerInstalled", "1");
		location.href = state.target;
		hide();
	}

	useEffect(() => {
		listenOn(document, "click", "[href^=screamer]", (event, target) => {
			if (localStorage.getItem("screamerInstalled")) {
				return;
			}

			const href = getAttribute(target, "href");
			if (href != null) {
				show(href);
			}

			event.preventDefault();
		});
	}, []);

	const visibility = {
		display: state.show ? "block" : "none"
	};

	return (
		<div>
			<div style={visibility} className="modal-backdrop fade show"></div>
			<div style={visibility} className="modal fade show" role="dialog" onClick={cancel}>
				<div className="modal-dialog modal-lg">
					<div className="modal-content" role="document">
						<div className="modal-header">
							<h4 className="modal-title">Confirm that Screamer Radio is installed</h4>
							<button type="button" className="btn-close" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<p className="mb-0">Make sure Screamer Radio is installed or these player control links will not work.</p>
						</div>
						<div className="modal-footer">
							<button className="btn btn-outline-secondary" onClick={cancel}>Cancel</button>
							<button className="btn btn-primary" onClick={confirm}>Screamer Radio is installed</button>
							<button className="btn btn-success" onClick={install}>Install now</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const div = document.createElement("div");
document.body.appendChild(div);

const root = createRoot(div);

root.render(<ConfirmPlayerModal />);
