import { useActions, useStateSelector } from "../Store";

import { search } from "./SearchStore";

const searchResultCount = 10;

export function QuickSearchBox() {
	const [dispatch, actions] = useActions();
	const query = useStateSelector(state => state.search.query);

	return (
		<form className="quick-search" method="GET" action="/stations/">
			<div className="input-group">
				<input name="q" type="text" className="form-control" placeholder="Search"
					onChange={e => void dispatch(search(actions, e.currentTarget.value, searchResultCount))}
					value={query}
				/>
				<button className="btn btn-outline-secondary">
					<i className="bi bi-search"></i>
					<span className="d-none">Search</span>
				</button>
			</div>
		</form>
	);
}
