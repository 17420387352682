import { shallowEqual } from "react-redux";

import { IStation } from "Api/V1/Models";

import { useStateSelector } from "../Store";

function StationResult(props: { station: IStation }) {
	return (
		<li key={props.station.id ?? ""} className={props.station.deleted != null ? "deleted" : ""}>
			<a href={props.station.links.page}>{props.station.title}</a>
		</li>
	);
}

function TagResult(props: { tag: string }) {
	return (
		<li key={props.tag}>
			<a href={`/stations/withtag/${props.tag}/`}>{props.tag}</a>
		</li>
	);
}

export function QuickSearchResults() {
	const appState = useStateSelector(state => ({
		failed: state.search.searchFailed,
		searching: state.search.searching,
		stations: state.search.stations,
		tags: state.search.tags,
		visible: state.search.searchCompleted || state.search.searchFailed
	}), shallowEqual);

	function renderCard<TItem>(title: string, items: TItem[] | undefined, renderItem: (item: TItem) => JSX.Element) {
		if (items == null) {
			return null;
		}

		return (
			<nav className="card condensed">
				<div className="card-body">
					<h5>{title}</h5>
					{items.length === 0
						? "No matches found"
						: (
							<ul className="list-unstyled mb-0">
								{items.map(renderItem)}
							</ul>
						)}
				</div>
			</nav>
		);
	}

	if (!appState.visible) {
		return <div className="header-row quicksearch"></div>;
	}

	if (appState.failed) {
		return (
			<div className="header-row quicksearch visible">
				<div className="container-xxl py-3">
					<div className="row">
						<div className="alert alert-danger"><strong>Sorry</strong>, search failed to complete :(</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={"header-row quicksearch visible"}>
			<div className="container-xxl py-3" style={{ opacity: appState.searching ? 0.5 : 1 }}>
				<div className="row justify-content-center">
					<div className="col-sm-6 col-md-5 col-lg-4">
						{renderCard("Tags", appState.tags, tag => <TagResult key={tag} tag={tag} />)}
					</div>
					<div className="col-sm-6 col-md-5 col-lg-4">
						{renderCard("Stations", appState.stations, station => <StationResult key={station.id ?? ""} station={station} />)}
					</div>
				</div>
			</div>
		</div>
	);
}
