import { Provider } from "react-redux";

import { renderAll } from "Helpers/ReactHelpers";
import { QuickSearchBox } from "Search/QuickSearchBox";
import { QuickSearchResults } from "Search/QuickSearchResults";

import { buildStore } from "./Store";

const { ActionProvider, actions, store } = buildStore();

renderAll(document.querySelectorAll("div[data-react=QuickSearchBox]"), () => (
	<Provider store={store}>
		<ActionProvider value={actions}>
			<QuickSearchBox />
		</ActionProvider>
	</Provider>
));

renderAll(document.querySelectorAll("div[data-react=QuickSearchResults]"), () => (
	<Provider store={store}>
		<ActionProvider value={actions}>
			<QuickSearchResults />
		</ActionProvider>
	</Provider>
));
