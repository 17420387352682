import { getAttribute, listenOn } from "Helpers/DomHelpers";

let activeModals: HTMLElement[] = [];

listenOn(document, "click", "a[data-toggle=modal]", (event, target) => {
	const href = getAttribute(target, "href");
	if (href == null) {
		return;
	}

	const targetId = href.replace("#", "");
	const modal = document.getElementById(targetId);
	if (modal != null) {
		modal.style.display = "block";
		document.body.classList.add("modal-open");
		activeModals.push(modal);
	}

	event.preventDefault();
});

listenOn(document, "click", ".modal-backdrop", event => {
	for (const modal of activeModals) {
		modal.style.display = "none";
	}

	activeModals = [];
	document.body.classList.remove("modal-open");

	event.preventDefault();
});
