﻿import "Modals/ConfirmPlayerModal";
import "Modals/DeclarativeModals";
import "Services/Timestamps";

import "./Header";

if (document.querySelector(".lightbox-gallery")) {
	void import("./LightboxGallery");
}

if (document.querySelector("div[data-react=StationList]")) {
	void import("./StationsPage");
}

if (document.querySelector("div[data-react=EditStation]")) {
	void import("./EditStationPage");
}
