import { friendlyDate } from "Helpers/DateHelpers";

function updateTimeSpans() {
	const spans = Array.from(document.querySelectorAll("span[data-timestamp]"));

	for (const span of spans) {
		const timestamp = span.getAttribute("data-timestamp");
		if (timestamp) {
			const date = new Date(timestamp);
			span.innerHTML = friendlyDate(date);
		}
	}
}

setInterval(updateTimeSpans, 5 * 1000);
updateTimeSpans();
